<template>
  <div>
    <el-dialog
      title="缴纳保证金"
      :visible.sync="isShow"
      width="600px"
      :before-close="handleClose"
      custom-class="payment"
      @open="openFn"
      destroy-on-close
    >
      <div class="content">
        <div class="top">
          <div class="hy-icon-bondY-fill"></div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="form"
            label-width="100px"
            label-position="left"
          >
            <el-form-item label="保证金金额：" prop="" class="bond">
              ￥<span class="bondPrice">{{ bond }}</span
              >万元
            </el-form-item>

            <el-form-item label="支付方式：" prop="">
              <el-radio
                v-for="(item, index) in Payment"
                :key="index"
                :label="item.label"
                v-model="PaymentModel"
              >
              </el-radio>
            </el-form-item>


            <el-form-item label="缴纳方式：" prop="radio" v-if="PaymentModel == '线上支付'">
              <el-radio
                v-for="(item, index) in balance"
                :key="index"
                v-model="ruleForm.radio"
                :label="item.bankType"
                :disabled="item.ifAsh"
              >
                <span v-if="item.bankType == 0">中信账户余额</span>
                <span v-else>中金账户余额</span>
                <span class="price">（￥{{ item.balance }}）</span>
                <span style="color: orange" v-if="item.bankType == 0"
                  >(建议使用)</span
                >
              </el-radio>
            </el-form-item>

            <el-form-item
              label="支付凭证："
              prop=""
              v-if="PaymentModel == '线下支付'"
            >
              <el-upload
                class="upload-demo"
                ref="upload"
                action="#"
                :limit="1"
                :http-request="httprRequest"
                accept=".jpg, .jpeg, .png,.pdf,.PDF"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png/jpeg/pdf文件
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div
        v-if="PaymentModel == '线上支付'"
          style="
            color: orange;
            text-align: center;
            margin-top: 10px;
            font-size: 10px;
            margin-left: 140px;
            text-align: left;
            line-height: 30px;
          "
        >
          竞价结束后，若未中标保证金将自动退回您的账户余额,若中标保证金在您履约完成后将自动退回
        </div>

        <div class="btn-wrap">
          <el-button @click="handleClose">取消</el-button>
          <el-button class="hy-btn" :disabled="istrue" @click="checkPawword"
            >确认支付</el-button
          >
        </div>
      </div>
    </el-dialog>
    <InputPasswordDialog
      :isShow="passWordIsShow"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="submit"
    />
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      custom-class="error-password"
      @before-close="dialogVisible = false"
    >
      <div class="content">
        <div class="left">
          <i class="hy-icon-cuowu"></i>
        </div>
        <div class="right">支付密码错误,请重试</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEorrorDialog()">取 消</el-button>
        <el-button type="primary" @click="rechecked">重试</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { reqPayBond, reqCheckPassword, bondOffline } from "@/api/hy";
import { ossUploadFile } from "@/api/public";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import md5 from "js-md5";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      PaymentModel: "线上支付",
      Payment: [
        {
          label: "线上支付",
        },
        {
          label: "线下支付",
        },
      ],
      istrue: false,
      password: null,
      uploadDialogIsShow: false,
      radio: true,
      bond: "",
      balance: "0",
      balances: "0",
      ruleForm: {
        radio: null,
      },
      rules: {
        num: [{ required: true, message: "请选择支付方式", trigger: "blur" }],
      },
      passWordIsShow: false,
      dialogVisible: false,
      payVoucher: "",
    };
  },
  components: {
    InputPasswordDialog: () => import("@/components/InputPasswordDialog"),
  },
  props: {
    isShow: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  methods: {
    httprRequest(e) {
      let otherFiles = e.file;
      var formData = new FormData();
      formData.append("file", otherFiles);
      ossUploadFile(formData).then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.payVoucher = res.data;
        }
      });
    },
    closeDialog(tag) {
      this[tag] = false;
    },
    openFn() {
      // this.bond = this.data.tradeBidding.bond
      console.log(this.data);
      this.bond = this.data.bondAmount;
      this.balance = this.data.accountList;
      this.ruleForm.radio = this.data.accountList[0].bankType;
    },
    handleClose() {
      this.$emit("close");
    },
    JsESACheckIsKey(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        // ESASignClient.ESAVerifyPin(_this.JsESAVerifyPin, null, false);
        this.sloce();
      } else {
        alert(msg);
        return false;
      }
    },
    sloce() {
      this.isShow = false;
      console.log(this.balance);
      console.log(this.bond);
      if (this.balance < this.bind * 10000) {
        this.$message({
          type: "success",
          message: "余额不足！",
        });
        return false;
      }
      if (this.data.isSetTradePassword == 1) {
        this.InputPasswordFn();
      } else {
        this.$confirm("您还没有设置交易密码！", "提示", {
          confirmButtonText: "去设置",
          cancelButtonText: "取消",
          type: "warning",
          customClass: "hy-confirm",
          iconClass: "hy-icon-mima",
        })
          .then(() => {
            this.$router.push({
              path: "/finance",
            });
          })
          .catch(() => {});
      }
    },
    checkPawword() {
      if (this.PaymentModel == "线上支付") {
        let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
        if (checkUkeyWhite == 0) {
          ESASignClient.ESACheckIsKey(this.JsESACheckIsKey, null, false);
        } else {
          this.sloce();
        }
      } else {
        console.log(this.PaymentModel);
        let config = {
          goodsId: this.$route.query.goodsId,
          payVoucher: this.payVoucher,
        };
        bondOffline(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "提交成功",
            });
            this.isShow = false;
          }else{
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        });
      }
    },
    InputPasswordFn() {
      // this.handleClose()
      this.passWordIsShow = true;
    },
    submitFn() {
      let config = {
        bankType: this.ruleForm.radio,
        goodsId: this.$route.query.goodsId,
        password: md5(this.password),
      };
      reqPayBond(config).then((ret) => {
        if (ret.code == 0) {
          this.$message({
            type: "success",
            message: "支付成功",
          });
          this.handleClose();
          this.$emit("paymentSuccess");
        } else {
          console.log("222222");
          // this.$message({
          //   type: "error",
          //   message: ret.message,
          // });
          if (ret.message == "支付密码有误") {
            this.$confirm(`${ret.message}。是否前往修改密码？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "关闭",
              cancelButtonClass: "hy-confirm-cancel-btn",
              type: "error",
              customClass: "hy-confirm",
              iconClass: "",
            })
              .then(() => {
                // this.$router.go(-1);
                this.$router.push({
                  path: "/finance",
                });
              })
              .catch(() => {
                this.handleClose();
                // this.$router.go(-1);
              });
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        }
      });
    },
    rechecked() {
      this.dialogVisible = false;
      this.$refs.InputPasswordDialog.clearPassword();
    },
    closeEorrorDialog() {
      this.dialogVisible = false;
      this.handleClose();
    },
    submit(val) {
      this.password = val;
      this.submitFn();
      // reqCheckPassword({password: md5(val), type: 1}).then((ret)=>{
      //   if (ret.code == 0) {
      //       this.submitFn()
      //     } else {
      //       this.dialogVisible = true
      //     }
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.payment .line {
  display: flex;
  margin: 10px;
}
.btn-wrap {
  text-align: right;
  margin-top: 50px;
}
::v-deep {
  .payment {
    border-radius: 10px !important;
  }
  .el-dialog__header {
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px 10px 0 0 !important;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .hy-btn {
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
    color: white;
  }
}
.top {
  display: flex;
  align-items: center;
  justify-content: center;
  .hy-icon-bondY-fill {
    font-size: 100px;
    color: #2079fb;
  }
  .el-form .el-form-item {
    margin-bottom: 0;
  }
}
.bondPrice {
  color: #d0111a !important;
  font-size: 36px;
  font-weight: bold;
}
.el-radio .price,
.bond {
  color: #d0111a !important;
}
::v-deep {
  .error-password .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left i {
    font-size: 40px;
    color: #fb4d4d;
    margin-right: 10px;
  }
}
</style>